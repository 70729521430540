const service = {
  HTTP: 'http',
  HTTPS: 'https',
  API_ADDR: 'ai.moriverse.xyz',
  AUTH_TOKEN_HEADER_KEY: 'Morigin-Auth-Token',

  //order service
  ORDER_SERVICE: "/orderservice",
  FETCH_PRODUCTS_METHOD: '/fetch_products',
  WX_PREPAY_METHDO: '/wx_prepay',
  CHECK_ORDER: "",
  EXECUTE_CREDIT_ORDER: "/execute_credit_order",

  //auth service
  AUTH_SERVICE: '/authservice',
  FETCH_WX_URL: '/fetch_wx_url',

  //product service
  PRODUCT_SERVICE: '/productservice',
  FETCH_CREDIT_PRODUCTS: '/fetch_credit_products',

  // Siwu service
  SIWU_SERVICE: '/siwuservice',
  GET_RED_POCKET: '/get_red_pocket',
};

export default service