import './PaymentSheet.less'
import wechat from '@/assets/payment/wechat_pay.svg'
import select from '@/assets/payment/correct.svg'
import { Loading, Toast } from 'react-vant'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { prepay, executeOrder } from '@/store/payment'
import { getSchema } from '@/common/schema_storage'

function PaymentSheet(props) {
  const dispatch = useDispatch()

  const [ loading, setLoading ] = useState(false)

  const onPay = () => {
    if(loading) return;
    setLoading(true)

    let params = {
      productId: props.product.productId,
      targetKey: props.targetKey,
      onSuccess: (param, orderId) => {
        onPullWeiXinPay(param, orderId)
      },
      onError: (errorCode) => {
        Toast.info({ message: errorCode === "USER_NOT_FOUND" ? "请输入正确的手机号或邀请码" : "网络错误" })
        setLoading(false)
      },
    }

    dispatch(prepay(params))
  }

  const onPullWeiXinPay = (param, orderId) => {
      function onBridgeReady() {
        // eslint-disable-next-line
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
            "appId": "wx0d455a8da3d2b57c",
            "timeStamp": param.timeStamp,
            "nonceStr": param.nonceStr,     
            "package": param.prepayId,
            "signType": "SHA",    
            "paySign": param.paySign 
        },
        function(res) {
        // eslint-disable-next-line
            if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                const parmas = {
                  orderId: orderId,
                  onSuccess: () => {
                    Toast.info({ message: "支付成功，即将跳转小程序" })

                    const params = {
                      onSuccess: (url) => window.location.href = url,
                      onError: () => Toast.info({ message: "跳转失败"})
                    }
                    getSchema(params)
                  },
                  onError: () => Toast.info({ message: "支付失败，请联系客服" }),
                  onFinal: () => setLoading(false)
                }

                dispatch(executeOrder(parmas))
            }else {
              setLoading(false)
            }
        });
    }
    if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
    } else {
        onBridgeReady();
    }
  }

  return (
    <div className='payment_sheet'>
      <div className='price'>{(props.product.price / 100).toFixed(2)}</div>

      <div className='payment_selects'>
        <div className='select_item'>
          <div className='banner'>
            <img src={wechat} alt=''/>
            <span>微信支付</span>
          </div>
          <div className='select'>
            <img src={select} alt=''/>
          </div>
        </div>
      </div>

      <div className='payment_btn' onClick={onPay}>
        {
          loading ? <Loading /> : <span>确认支付</span>
        }
      </div>
    </div>
  )
}

export default PaymentSheet