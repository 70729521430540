export default ({
  set: function(key, value) {
      localStorage.setItem(key, JSON.stringify(value))
  },

  get: function(key) {
      let val = localStorage.getItem(key)
      return val ? JSON.parse(val) : null;
  },

  remove: function(key) {
      localStorage.removeItem(key);
  }
})