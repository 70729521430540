import './HomeView.less'
import home_1 from '@/assets/home-1.jpg'
import home_2 from '@/assets/home-2.jpg'
import home_3 from '@/assets/home-3.jpg'
import home_4 from '@/assets/home-4.jpg'

function HomeView (props) {
  document.title = props.title
  
  return (
    <div className='home_view'>
      <img className='img' src={home_1} alt=''/>
      <img className='img' src={home_2} alt=''/>
      <img className='img' src={home_3} alt=''/>
      <img className='img' src={home_4} alt=''/>
      <span className='footer'><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022085317号</a> | © 2023 深圳市遇见森林科技有限责任公司</span>
    </div>
  )
}

export default HomeView