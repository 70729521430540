import './OptionPanel.less'
import credit from '@/assets/payment/credit.svg'
import PaymentSheet from '../payment_sheet/PaymentSheet';
import { ActionSheet, Loading, Toast } from 'react-vant';
import { useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { fetchCreditProducts } from '@/store/payment';

function OptionPanel() {
  const dispatch = useDispatch()

  const [ cny, setCny ] = useState(false)
  const [ products, setProducts ] = useState([])
  const [ currentProdcut, setCurrent ] = useState()
  const [ targetKey, setTargetKey ] = useState('')
  const [visible, setVisible] = useState(false)
  const [ loading, setLoading ] = useState(true)

  const agreement = "https://resources.moriverse.xyz/agreements/payment.html"

  const onCancel = () => setVisible(false)

  const onChange = (event) => setTargetKey(event.target.value)

  const onSelect = (productId) => setCurrent(products.find(product => product.productId === productId))

  const onPay = () => {
    if(!targetKey) {
      Toast.info({ message: '请输入手机号或邀请码'})
      return;
    }
    setVisible(true)
  }

  const fetchProducts = () => {
    setLoading(true)
    let parmas = {
      onSuccess: (products, cny) => {
        setProducts(products)
        setCurrent(products[0])
        setCny(cny)
      },
      onError: () => Toast.info({ message: "网络错误" }),
      onFinal: () => setLoading(false)
    }
    dispatch(fetchCreditProducts(parmas))
  }
  // eslint-disable-next-line
  useEffect(() => fetchProducts(), [])

  return (
    <div className='option_panel'>
      { 
        loading ? 
        <div className='loading'>
          <Loading type="ball" />
        </div> : 
        <div className='payment_content'>
          <div className='header'>
            <span className='title'>充值账户</span>
            <span>请确认你的手机号或邀请码正确，避免误充</span>
          </div>

          {
            cny ? <span>* 限时活动 - </span> : null
          }

          <input 
            placeholder='请输入您的手机号或邀请码'
            type='text'
            value={targetKey}
            onChange={onChange}
          />

          <div className='payment_options'>
            { 
              products.map(product => {
                return (
                  <div 
                    className='option' 
                    key={product?.productId}
                    style={{border: currentProdcut?.productId === product?.productId ? '1.5px solid #FE6F9B' : '1.5px solid rgba(0, 0, 0, 0.05'}}
                    onClick={() => onSelect(product?.productId)}
                  >
                    { 
                      product.tag ? 
                        <div className='discount'>{product?.tag}</div> : <></> 
                    }
                    <div className='credit'>
                      <img src={credit} alt=''/>
                      <span>{product?.description}</span>  
                    </div> 
                    <div className='price'>￥ { (product?.price / 100).toFixed(2) }</div>
                  </div>
                )
              })
            }
          </div>

          <div className='footer'>
            <div className='payment_btn' onClick={onPay}>
              立即充值 {(currentProdcut?.price / 100).toFixed(2)} 元
            </div>
            <a className='agreement' href={agreement}>充值即同意《45AI积分充值协议》</a>
          </div>

          <ActionSheet 
            visible={visible} 
            onCancel={onCancel} 
            duration={300}
            title="支付"
          >
            <PaymentSheet product={currentProdcut} targetKey={targetKey}/>
          </ActionSheet>
        </div>
      }

    </div>
  )
}

export default OptionPanel