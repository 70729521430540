import './PaymentView.less'
import credit from '@/assets/payment/credit.svg'
import miniprgress from '@/assets/payment/miniprogress.svg'
import service from '@/assets/payment/service.svg'
import OptionPanel from './component/option_panel/OptionPanel'
import { getSchema } from '@/common/schema_storage'
import { Toast, Overlay } from 'react-vant'
import { WarningO } from '@react-vant/icons';

function PaymentView(props) {
  document.title = props.title

  const onClickMiniProgress = () => {
    const request = {
      onSuccess: (url) => window.location.href = url,
      onError: () => Toast.info({ message: "网络错误" })
    }
    getSchema(request)
  }

  const onService = () => {
    window.location.href = "https://work.weixin.qq.com/kfid/kfc0ed19c21abf8023e"
  }

  const isWeiXin = () => {
    const ua = navigator.userAgent.toLowerCase()
    // eslint-disable-next-line
    const isWXWork = ua.match(/wxwork/i) == 'wxwork'
    // eslint-disable-next-line
    return !isWXWork && ua.match(/micromessenger/i) == 'micromessenger'
  }

  return (
    <div className='payment_view'>
      <div className='title'>
        <span>如何获得免费胶囊</span>
        <div className='service' onClick={onService}>
          <img src={service} alt=''/>
          <span>联系客服</span>
        </div>
      </div>
      <div className='introduce'>
        <div className='item'>
          <span>邀请好友注册登录</span>
          <div className='credit'>
            <img src={credit} alt='' className='icon'/>
            <span className='credits'>+5</span>
          </div>
        </div>
        <div className='item'>
          <span>好友充值下单双方获得</span>
          <div className='credit'>
            <img src={credit} alt='' className='icon'/>
            <span className='credits'>+20</span>
          </div>
        </div>
        <div className='item'>
          <span>查看积分是否到账请到45AI小程序-我的-胶卷明细 查看明细</span>
        </div>
        <div className='item' onClick={onClickMiniProgress}>
          <span className='activity'>*活动具体详情请前往 
            <div className='activity'>
              <img src={miniprgress} alt=''/>
              <span className='miniprogress'>小程序</span>
            </div>
            主页查看</span>
        </div>
      </div>
      <OptionPanel />
      <Overlay visible={ !isWeiXin() }>
        <div className='overlay'>
          <WarningO color='#fff' fontSize='6em'/>
          <div>请在微信客户端打开</div>
        </div>
      </Overlay>
    </div>
  )
}

export default PaymentView