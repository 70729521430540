import storage from './local_storage'
import { getWechatScheme } from '../store/payment'
import store from '../store'

const setSchemaToStorage = (url) => {
  const schema = {
    url,
    createdTime: +new Date()
  }
  storage.set("Schema_Url", JSON.stringify(schema))
}

export const getSchema = (request) => {

  const schema = JSON.parse(storage.get("Schema_Url"))

  const timeStamp = +new Date() - schema?.createdTime
  const day = timeStamp / 1000 / 60 / 60 / 24
  if(day >= 30 || !schema) {
    const params = {
      onSuccess: (openLink) => {
        setSchemaToStorage(openLink);
        request.onSuccess?.(openLink)
      },
      onError: () => request.onError?.(),
      onFinal: () => request.onFinal?.()
    }
    store.dispatch(getWechatScheme(params))
  }else request.onSuccess?.(schema.url)
} 