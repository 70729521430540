import './App.css';
import { Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import PaymentView from "@/page/payment/PaymentView"
import HomeView from "@/page/home/HomeView"
import RedPocketView from "@/page/red_pocket/RedPocketView"
import store from '@/store';


function App() {
  return (
    <Provider store={store}>
      <div className="App">
          <Routes>
            <Route path="/" element={<HomeView title={"45AI"}/>}/>
            <Route path="/payment" element={<PaymentView title={"45AI - 胶卷充值"}/>}/>
            <Route path="/red_pocket" element={<RedPocketView title={"新春活动"}/>}/>
          </Routes>
      </div>
    </Provider>
  );
}

export default App;
