import { configureStore } from "@reduxjs/toolkit";
import paymentSlice from "./payment.js";
import redPocketSlice from "./red_pocket.js";
import thunk from "redux-thunk";

export default configureStore({
  reducer: {
    paymentSlice,
    redPocketSlice
  },
  middleware: [thunk]
})