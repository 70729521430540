import './RedPocketView.less'

import claimed from '@/assets/red_pocket/claimed.webp'
import failed from '@/assets/red_pocket/failed.webp'

import { Loading } from 'react-vant'
import { useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { useSearchParams } from "react-router-dom";

import { getRedPocket } from '@/store/red_pocket'

function ResultView(props) {
  const ok = props.data.result === "OK"

  let text = ""
  switch (props.data.result) {
    case "OK":
      text = "恭喜获得 " + props.data.amount + " 胶卷！"
      break

    case "EMPTY":
      text = "很遗憾，今天的胶卷已经被抢光了！明天再来吧～"
      break

    case "CLAIMED":
      text = "你今天已经抢过胶卷啦！明天再来吧～"
      break

    case "FAILED":
      text = "抱歉，后台出错了～请重试"
      break

    case "ERROR":
      text = "出错了"
      break

    case "NO_USER":
      text = "你好像还没有登录～"
      break
  }

  return (
    <div className='result'>
      <img src={ ok ? claimed : failed } alt=''/>
      <span>{text}</span>
    </div>
  )
}

function RedPocketView(props) {
  document.title = props.title

  const dispatch = useDispatch()
  const [ loading, setLoading ] = useState(false)
  const [ data, setData ] = useState({})
  const [searchParams] = useSearchParams();

  const init = () => {
    if (loading) return
    setLoading(true)

    const request = {
      auth: searchParams.get("auth"),
      onSuccess: (data) => setData(data),
      onError: () => setData({result: "ERROR"}),
      onFinal: () => setLoading(false)
    }
    dispatch(getRedPocket(request))
  }

  // eslint-disable-next-line
  useEffect(() => init(), [])

  return (
    <div className='red_pocket_view'>
      {
        loading ? <Loading /> : <ResultView data={data}/>
      }
    </div>
  )
}

export default RedPocketView