import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpClient from "../common/http_client";
import service_constant from "../constant/service_constant";

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
  }
})

export const fetchCreditProducts = createAsyncThunk('payment/fetchCreditProducts', (request) => {
  let params = {}
  return HttpClient.send(
    service_constant.PRODUCT_SERVICE,
    service_constant.FETCH_CREDIT_PRODUCTS,
    params,
    (resp) => {
      const products = resp.data.products
      if(products) {
        request.onSuccess?.(products, resp.data.cny)
      }else request.onError?.()
    },
    request.onError,
    request.onFinal
  )
})

export const getWechatScheme = createAsyncThunk('payment/getWechatScheme', (request) => {
  let params = {
    referCode: request.referCode
  }

  HttpClient.send(
    service_constant.AUTH_SERVICE,
    service_constant.FETCH_WX_URL,
    params,
    resp => {
      if(resp.data.url) {
        request.onSuccess?.(resp.data.url)
      }else request.onError?.()
    },
    request.onError,
    request.onFinal
  )
})

export const prepay = createAsyncThunk('payment/prepay', (request) => {
  let params = {
    type: 'WX',
    productId: request.productId,
    targetKey: request.targetKey,
  }

  return HttpClient.send(
    service_constant.ORDER_SERVICE,
    service_constant.WX_PREPAY_METHDO,
    params,
    resp => {
      const param = resp.data.wxPayParam
      if (param) {
        request.onSuccess?.(param, resp.data.orderId)
      } else request.onError?.(resp.data.errorCode)
    },
    request.onError,
    request.onFinal
  )
})

export const executeOrder = createAsyncThunk('payment/executeOrder', (request) => {
  let params = {
    orderId: request.orderId
  }

  return HttpClient.send(
    service_constant.ORDER_SERVICE,
    service_constant.EXECUTE_CREDIT_ORDER,
    params,
    resp => {
      let remainCredit = resp.data.remainCredit
      if (remainCredit) {
        request.onSuccess?.()
      } else {
        request.onError?.()
      }
    },
    request.onError,
    request.onFinal
  )
})


export default paymentSlice.reducer