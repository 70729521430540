import service from '@/constant/service_constant.js'
import axios from 'axios'

export default class HttpClient {
    static send(servicename, method_name, params, onSuccess, onError, onFinal, auth="") {
        let endpoint = HttpClient.createEndpoint() + servicename + method_name

        let config = {
            headers: { "Morigin-Auth-Token": auth }
        }
        axios.post(endpoint, params, config)
            .then((resp) => {
                if (resp) {
                    onSuccess?.(resp);
                } else {
                    onError?.();
                }
            })
            .catch(() => {
                onError?.();
            })
            .finally(onFinal)
    }

    static createEndpoint() {
        return service.HTTPS + '://' + service.API_ADDR
    }
}