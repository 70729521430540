import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpClient from "../common/http_client";
import service_constant from "../constant/service_constant";

export const redPocketSlice = createSlice({
  name: 'red_pocket',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {}
})

export const getRedPocket = createAsyncThunk('siwu/get_red_pocket', (request) => {
    return HttpClient.send(
        service_constant.SIWU_SERVICE,
        service_constant.GET_RED_POCKET,
        {},
        resp => {
            if (resp.data) request.onSuccess?.(resp.data)
            else request.onError?.()
        },
        request.onError,
        request.onFinal,
        request.auth
    )
})

export default redPocketSlice.reducer